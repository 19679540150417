import React from "react";

function BackButtonSvg({ className, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23.375C18.2816 23.375 23.375 18.2816 23.375 12C23.375 5.71836 18.2816 0.625001 12 0.625001C5.71836 0.625 0.625 5.71836 0.624999 12C0.624999 18.2816 5.71836 23.375 12 23.375ZM8.34121 11.8248L13.8865 7.79023C13.9169 7.76839 13.9528 7.75535 13.9901 7.75254C14.0274 7.74974 14.0648 7.75728 14.0981 7.77433C14.1315 7.79139 14.1594 7.8173 14.179 7.84922C14.1985 7.88114 14.2089 7.91784 14.209 7.95527L14.209 16.0193C14.2091 16.0568 14.1988 16.0937 14.1793 16.1257C14.1598 16.1577 14.1318 16.1837 14.0984 16.2008C14.065 16.2179 14.0275 16.2254 13.9901 16.2225C13.9527 16.2196 13.9169 16.2064 13.8865 16.1844L8.34121 12.1523C8.31501 12.1338 8.29364 12.1093 8.27889 12.0808C8.26414 12.0523 8.25644 12.0207 8.25644 11.9886C8.25644 11.9565 8.26414 11.9249 8.27889 11.8964C8.29364 11.8679 8.31501 11.8433 8.34121 11.8248Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export default BackButtonSvg;

import React from "react";

export default function GoogleSvg({ className, onClick }) {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26599 9.765C5.73387 8.34945 6.63685 7.11781 7.84611 6.2458C9.05536 5.37378 10.5091 4.90594 12 4.909C13.69 4.909 15.218 5.509 16.418 6.491L19.91 3C17.782 1.145 15.055 0 12 0C7.26999 0 3.19799 2.698 1.23999 6.65L5.26599 9.765Z"
        fill="#EA4335"
      />
      <path
        d="M16.0401 18.0131C14.9501 18.7161 13.5661 19.0911 12.0001 19.0911C10.5151 19.0941 9.06686 18.63 7.8603 17.7644C6.65373 16.8988 5.75 15.6757 5.27706 14.2681L1.23706 17.3351C2.22831 19.3414 3.76233 21.0295 5.66488 22.2076C7.56744 23.3858 9.76227 24.0068 12.0001 24.0001C14.9331 24.0001 17.7351 22.9571 19.8341 21.0001L16.0411 18.0131H16.0401Z"
        fill="#34A853"
      />
      <path
        d="M19.834 21.0001C22.029 18.9521 23.454 15.9041 23.454 12.0001C23.454 11.2901 23.345 10.5271 23.182 9.81812H12V14.4551H18.436C18.119 16.0141 17.266 17.2211 16.041 18.0131L19.834 21.0001Z"
        fill="#4A90E2"
      />
      <path
        d="M5.27712 14.2679C5.03247 13.5368 4.90819 12.7708 4.90912 11.9999C4.90912 11.2179 5.03412 10.4669 5.26612 9.7649L1.24012 6.6499C0.416494 8.31259 -0.00807994 10.1444 0.000116455 11.9999C0.000116455 13.9199 0.445116 15.7299 1.23712 17.3349L5.27712 14.2679Z"
        fill="#FBBC05"
      />
    </svg>
  );
}
